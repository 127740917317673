import * as React from "react"
import { Table } from "react-bootstrap"
import { Link } from "gatsby"

import Layout from "../components/layout"
import BrowseTab from "../components/browseTab"
import SearchBox from "../components/searchBox"

import { fixName } from "../../utils/name_utils"

const BrowsePage = () => {
  const [suggestions, setSuggestions] = React.useState<Array<string>>([])

  return (
    <Layout pageTitle="Find your SoundName">
      <BrowseTab />
      <SearchBox
        onSearch={names => {
          setSuggestions(names)
        }}
      />
      {suggestions.length > 0 ? (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {suggestions.map(name => (
              <tr key={name}>
                <td>
                  <Link to={`/name/${name.toLowerCase()}`}>
                    {fixName(name)}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </Layout>
  )
}

export default BrowsePage
