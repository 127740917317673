import * as React from "react"
import Form from "react-bootstrap/Form"

import axios from "axios"
import { debounce } from "debounce"

export type SearchResult = {
  results: Array<string>
}

type Props = {
  onSearch: (names: Array<string>) => void
}

const SearchBox = ({ onSearch }: Props) => {
  const onChange = React.useCallback(event => {
    const searchString = event.target.value

    axios
      .get<SearchResult>("/api/search", {
        params: {
          s: searchString,
        },
      })
      .then(response => {
        onSearch(response.data.results)
      })
      .catch(() => {
        onSearch([])
      })
  }, [])

  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control
        type="search"
        onChange={debounce(onChange, 250)}
        placeholder="Enter a name"
        required
      />
      <Form.Text className="text-muted">Enter your name here</Form.Text>
    </Form.Group>
  )
}

export default SearchBox
